.cart-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .cart-layout {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .cart-items {
    flex: 1;
    padding-right: 20px;
  }
  
  .cart-prices {
    width: 100px;
    text-align: right;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
  }
  
  button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #ff3333;
  }
  
  .cart-summary {
    margin-top: 20px;
    text-align: right;
    font-weight: bold;
  }
  
  .cart-summary p {
    margin: 5px 0;
  }
  
  .frequently-bought-together {
    margin-top: 40px;
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
  }
  
  .frequently-bought-together h3 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .frequently-bought-together ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .frequently-bought-together li {
    flex-basis: calc(33.333% - 10px);
    margin-bottom: 15px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .frequently-bought-together li span {
    margin-bottom: 10px;
  }
  
  .frequently-bought-together button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .frequently-bought-together button:hover {
    background-color: #45a049;
  }
  
  .purchase-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
  }
  
  .purchase-button:hover {
    background-color: #45a049;
  }