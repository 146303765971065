/* Overall Shirts Page */
.mugs-page {
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;  /* Center the container */
    padding: 20px;
  }
  
  /* Product Container (Flexbox for side-by-side layout) */
  .product-container {
    display: flex;
    gap: 1px;
    align-items: normal;
  }
  
  /* Main Image */
  .main-image {
    width: 70%; /* Adjust width as needed */
    height: fit-content;
  }
  
  .main-image img {
    width: 100%;
    height: auto;
  }
  
  /* Thumbnail Gallery */
  .thumbnail-gallery {
    width: 60%; /* Adjust width as needed */
  }
  
  .carousel {
    display: flexbox;
    flex-direction: column;
    gap: 5px;
    
  }
  
  /* Carousel Thumbnail Styling */
  .carousel .thumb {
    border: 2px solid transparent; /* Add a border on hover */
    margin: 5px;
  }
  
  .carousel .thumb:hover {
    border-color: #f0c14b; /* Change border color on hover */
  }
  
  .carousel .thumb.selected,
  .carousel .thumb:active {
    border-color: #282c34; /* Change border color when selected */
  }
  
  /* Product Details */
  .product-details {
    width: 200%; /* Adjust width as needed */
  }
  
  .product-details h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .description {
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Customization Options */
  .options ul {
    list-style: square;
    padding: 0;
  }
  
  .options li {
    margin-bottom: 10px;
  }
  
  .buttons {
    display: flexbox; /* Use flexbox for layout */
    gap: 10px;     /* Adjust the spacing between buttons */
  }
  
  .customize-button,
  .add-to-cart {
    background-color: #f0c14b;
    border: orange 5px solid;
    color: #111;
    padding: 10px 20px; /* Consistent padding for both buttons */
    cursor: pointer;
    border-radius: 3px;
    flex-grow: 1;   /* Allow buttons to grow equally to fill space */
    text-align: center;
  }
  
  .option {
    margin-bottom: 15px; /* Add spacing between options */
  }
  
  .option label {
    display: block; /* Make labels appear on their own line */
    margin-bottom: 5px;
  }
  
  .option select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* Make dropdowns fill their container width */
  }
  
  /* Responsive Adjustments (Optional) */
  @media (max-width: 768px) { 
    .product-container {
      flex-direction: column; /* Stack on smaller screens */
    }
    .main-image,
    .thumbnail-gallery, 
    .product-details {
      width: 100%;
    }
  
    .thumbnail-gallery {
      margin-bottom: 20px; /* Add space below gallery on mobile */
    }
  }
  
  