* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header Styles */
.header {
  background-color: orange;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  padding: 10px 0;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  display: flex;
  align-items: center;
}

.logo {
  height: 60px;
  width: auto;
}

.header-links-container {
  display: flex;
  align-items: center;
}

.header-links {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.header-link {
  margin-left: 20px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  transition: color 0.3s ease;
}

.header-link:hover {
  color: #003d82;
}

.cart {
  margin-left: 20px;
  position: relative;
}

.cart-icon {
  font-size: 24px;
  cursor: pointer;
}

.login-button, .logout-button, .contact-form button {
  margin-left: 20px;
  padding: 8px 16px;
  background-color: #003d82;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.login-button:hover, .logout-button:hover, .contact-form button:hover {
  background-color: #002855;
}

.checkout-button {
  background-color: orange;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #e67e00;
}

/* Main Content Styles */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.hero, .clients, .about, .contact {
  padding: 60px 20px;
  margin: 0 auto;
  min-width: 1350px;
  width: fit-content;
}

.hero {
  background-color: #f8f9fa;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.hero-content {
  flex: 1;
  padding-right: 40px;
}

.hero h1 {
  font-size: 2.5rem;
  color: #003d82;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2rem;
  color: #666;
}

.ipad-container {
  flex:2;
  width: 100%;
}

.clients h2, .about h2, .contact h2, .facts h2 {
  font-size: 2rem;
  color: #003d82;
  margin-bottom: 30px;
  text-align: center;
}

.client-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  justify-items: center;
}

.client-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.client-logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.client-logo:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.client-logo img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.client-item h3 {
  font-size: 1.2rem;
  color: #333;
  margin-top: 10px;
}

.about p {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.1rem;
  color: #666;
}

.contact {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #f8f9fa;
}

.contact-form, .contact-info {
  flex: 1;
  padding: 0 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.contact-info p {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #666;
}

/* Footer Styles */
.footer {
  background-color: orange;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9rem;
}

.facts {
  background-color: #f8f9fa;
  padding: 60px 20px;
}

.facts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  justify-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.fact-item {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fact-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.fact-item h3 {
  font-size: 2.5rem;
  color: orange;
  margin-bottom: 10px;
}

.fact-item p {
  font-size: 1.1rem;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-links-container {
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .facts-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .fact-item h3 {
    font-size: 2rem;
  }

  .fact-item p {
    font-size: 1rem;
  }

  .header-links {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }

  .header-link {
    margin: 10px 0;
  }

  .cart, .login-button, .logout-button {
    margin-left: 0;
    margin-top: 10px;
  }

  .hero {
    flex-direction: column;
    text-align: center;
  }

  .hero-content {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .client-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .client-logo {
    width: 120px;
    height: 120px;
  }

  .contact {
    flex-direction: column;
  }

  .contact-form, .contact-info {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
}

/* Animation Styles */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation: fadeInUp 0.6s ease-out;
}