.banner-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.banner-page h1 {
  text-align: center;
  margin-bottom: 30px;
}

/* Banner List Styles */
.banner-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.banner-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  text-decoration: none;
  color: inherit;
}

.banner-item:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.banner-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-info {
  padding: 15px;
}

.banner-name {
  font-size: 1.1em;
  margin: 0 0 10px 0;
}

.banner-type {
  font-size: 0.9em;
  color: #666;
  margin: 0;
}

/* Banner Detail Styles */
.product-container {
  display: flex;
  flex-direction: space-between;
  gap: 30px;
}

.image-gallery {
  flex: 1;
  min-width: 300px;
}

.image-gallery img {
  width: 500px;
  height: auto;
  border-radius: 8px;
}

.product-details {
  flex: 1;
  min-width: 300px;
}

.product-details h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
}

.description {
  margin-bottom: 20px;
  line-height: 1.6;
}

.options {
  margin-bottom: 20px;
}

.options h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.options ul {
  list-style-type: none;
  padding-left: 0;
}

.options li {
  margin-bottom: 10px;
}

.left-side {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.right-side {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.add-to-cart {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.add-to-cart:hover {
  background-color: #45a049;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-container {
    flex-direction: column;
  }
  
  .image-gallery, .product-details {
    width: 100%;
  }
}