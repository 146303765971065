.qr-code-generator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.color-pickers {
  display: flex;
  gap: 1rem;
}

.qr-style-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.qr-preview {
  margin-top: 1rem;
}