.cards-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    overflow-x: hidden;
  }
  
  .cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    padding: 40px;
    justify-content: center;
    margin: 10px auto;
    max-width: 1200px;
  }
  
  .cards-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cards-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(2, 2, 2, 0.2);
  }
  
  .cards-item img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #e1a21c;
    text-align: center;
  }
  
  .cards-image {
    margin-bottom: 20px;
  }
  
  .cards-info {
    margin-bottom: 20px;
  }
  
  .cards-info h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .cards-grid {
      gap: 20px;
      padding: 20px;
    }
  }