/* left-side-bar-component.css */
.left-sidebar {
  background: #f9f9f9;
  padding: 10px;
  display: flex;
  flex-direction: row; /* Change to row for horizontal alignment */
  align-items: flex-start;
  height: 100%;
  width: 100%; /* Ensure it spans the width of the container */
}

.left-sidebar .MuiGrid-container {
  flex-direction: row; /* Change to row to align items horizontally */
  width: 100%;
}

.left-sidebar .MuiGrid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 10px; /* Add some margin between items */
}

.left-sidebar .MuiTooltip-tooltip {
  background-color: #555;
  color: #fff;
  font-size: 12px;
}

.left-sidebar .MuiSvgIcon-root {
  font-size: 40px;
  color: #3f51b5;
  transition: color 0.3s;
}

.left-sidebar .MuiSvgIcon-root:hover {
  color: #303f9f;
}

.left-sidebar .icon-label {
  margin-top: 4px;
  font-size: 12px;
  text-align: center;
}
