.image-upload-container {
    margin: 20px;
}

.image-upload {
    margin-bottom: 20px;
}

.success-message {
    color: green;
    margin-top: 10px;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.error-message button {
    margin-left: 10px;
    cursor: pointer;
}

.uploaded-images h3 {
    margin-bottom: 10px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
}

.image-item {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.image-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.image-name {
    margin-top: 5px;
    text-align: center;
    font-size: 0.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.image-item:hover .delete-button {
    opacity: 1;
}

.delete-button:hover {
    background-color: rgba(255, 0, 0, 0.9);
}