.business-card-page {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .product-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  
  .left-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .right-side {
    flex: 1;
  }
  
  .image-gallery {
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .image-gallery img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .product-details {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .product-details h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .product-details h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .MuiFormControl-root {
    margin-bottom: 20px;
  }
  
  .MuiButton-root {
    margin-right: 10px;
  }
  
  .MuiTabs-root {
    margin-bottom: 20px;
  }
  
  .MuiTab-root {
    text-transform: none;
  }
  
  .MuiTypography-root {
    color: #333;
  }
  
  @media (max-width: 768px) {
    .product-container {
      flex-direction: column;
    }
  
    .left-side,
    .right-side {
      width: 100%;
    }
  }
  
  /* Additional styles for the business card list view */
  .business-cards-page {
    padding: 20px;
  }
  
  .card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .card-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .card-item:hover {
    transform: scale(1.05);
  }
  
  .card-item.selected {
    border-color: #1976d2;
    box-shadow: 0 0 0 2px #1976d2;
  }
  
  .card-item img {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
  
  .card-item p {
    padding: 10px;
    text-align: center;
    font-weight: bold;
  }
  
  button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #1565c0;
  }