/* Overall Shirts Page */
.shirts-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Product Container (Flexbox for side-by-side layout) */
.product-container {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

/* Product Media */
.product-media {
  width: 600px;
  height: 600px; 
}

/* Thumbnail Gallery */
.thumbnail-gallery {
  width: 100%;
}

.carousel {
  display: flex;
  width: 500px;
  height: 1200px;
  flex-direction: column;
  gap: 1px;
}

/* Carousel Thumbnail Styling */
.carousel .thumb {
  border: 2px solid transparent;
  margin: 5px;
}

.carousel .thumb:hover {
  border-color: #f0c14b;
}

.carousel .thumb.selected,
.carousel .thumb:active {
  border-color: #282c34;
}

/* Product Details */
.product-details {
  width: 70%;
}

.product-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Customization Options */
.options ul {
  list-style: none;
  padding: 0;
}

.options li {
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.customize-button,
.add-to-cart {
  background-color: #f0c14b;
  border: none;
  color: #111;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 3px;
}

.option {
  margin-bottom: 15px;
}

.option label {
  display: block;
  margin-bottom: 5px;
}

.option select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

/* Product Description */
.product-description {
  width: 100%;
  margin-top: 30px;
}

.description {
  line-height: 1.6;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .product-container {
    flex-direction: column;
  }
  .product-media,
  .product-details,
  .product-description {
    width: 100%;
  }
}

/* Shirts List Page Styles (if needed) */
.shirts-list-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  align-content: space-around;
}

.shirts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.shirt-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.shirt-item:hover {
  transform: scale(1.05);
}

.shirt-item img {
  width: 100%;
  max-width: 180px;
  height: auto;
  object-fit: cover;
  margin-bottom: 10px;
}

.shirt-item p {
  font-weight: bold;
  margin: 0;
}

@media (max-width: 600px) {
  .shirts-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}