.design-canvas-container {
  border: 1px solid #ccc;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.design-canvas {
  width: 100%;
  height: 100%;
  position: relative;
}

.background-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.background-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.canvas-element {
  position: absolute;
  z-index: 1;
}

.canvas-element img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 2;
}

.resize-handle-n { top: -5px; left: 50%; transform: translateX(-50%); cursor: n-resize; }
.resize-handle-ne { top: -5px; right: -5px; cursor: ne-resize; }
.resize-handle-e { top: 50%; right: -5px; transform: translateY(-50%); cursor: e-resize; }
.resize-handle-se { bottom: -5px; right: -5px; cursor: se-resize; }
.resize-handle-s { bottom: -5px; left: 50%; transform: translateX(-50%); cursor: s-resize; }
.resize-handle-sw { bottom: -5px; left: -5px; cursor: sw-resize; }
.resize-handle-w { top: 50%; left: -5px; transform: translateY(-50%); cursor: w-resize; }
.resize-handle-nw { top: -5px; left: -5px; cursor: nw-resize; }

.remove-button {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 20px;
  height: 20px;
  background-color: #ff4136;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  z-index: 2;
}

.preview-mode .resize-handle,
.preview-mode .remove-button {
  display: none;
}