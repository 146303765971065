/* IpadContainer.css */
.ipad-container {
  background-color: #1a1a1a; /* Dark gray for iPad frame */
  margin: 40px auto;
  width: 600px;
  height: 800px; 
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  padding: 10px;
  position: relative;
  overflow: hidden; /* Prevent content from spilling out */
}

.ipad-screen {
  background-color: #000; /* Black background for screen */
  flex: 1; /* Allow screen to take up available space */
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.icon-grid {
  background-image: url('/src/screen.jpg');
  background-size: cover;
  background-position: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;
  padding: 20px;
  overflow-y: auto;
  height: auto;  /* Let content determine height */
  max-height: 100%; /* Ensure it doesn't exceed the screen */
}

.home-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #2a2a2a;
  border: 2px solid #3a3a3a;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}


.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px;
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease;
  aspect-ratio: 4 / 4;
}

.icon:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.9);
}

.icon svg {
  font-size: 2.5rem;  /* Increase icon size */
  margin-bottom: 10px;  /* Add some space between icon and text */
}

.icon p {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  word-wrap: break-word;
  max-width: 100%;
}

/* Scrollbar styling */
.icon-grid::-webkit-scrollbar {
  width: 8px;
}

.icon-grid::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.icon-grid::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.icon-grid::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ipad-container {
    width: 90%;
    max-width: 600px;
    height: 30vh;
  }

  .icon-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }

  .icon img {
    width: 40px;
    height: 40px;
  }

  .icon p {
    font-size: 10px;
  }

  .home-button {
    width: 40px;
    height: 40px;
  }
}
