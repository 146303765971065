/* customizationPage.css */
.customization-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  padding: 10px;
  background: #f0f0f0; /* Optional: Set a background for the header */
}

.product-options {
  display: flex;
  justify-content: space-between;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.canvas-and-panel {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.left-sidebar {
  flex: 0 0 100px; /* Set a fixed width for sidebar */
  overflow: hidden;
  overflow-y: auto;
}

.canvas-wrapper {
  flex: 1;
  overflow: auto;
  position: relative;
}

.active-panel {
  position: absolute;
  left: 100px; 
  top: 0;
  bottom: 0;
  width: 300px; 
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  overflow: auto;
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 80%;
  height: 80%;
  overflow: auto;
}

.close-preview {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff0000;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.footer {
  background: #b5651d; /* Brown background for the footer */
  color: white;
  text-align: center;
  padding: 10px;
  flex-shrink: 0;
}


.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.canvas-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}